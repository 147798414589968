import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    isAuthenticated: false,
    user: null,
    accessToken: null,
    isLoading: false,
    error: null
};
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginRequest: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        loginSuccess: (state, action) => {
            state.isLoading = false;
            state.isAuthenticated = true;
            state.user = action.payload.user;
            state.accessToken = action.payload.accessToken;
            state.error = null;
        },
        loginFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        logoutRequest: (state) => {
            state.isLoading = true;
        },
        logoutSuccess: (state) => {
            state.isLoading = false;
            state.isAuthenticated = false;
            state.user = null;
            state.accessToken = null;
        },
        updateAccessToken: (state, action) => {
            state.accessToken = action.payload;
        },
        checkSessionRequest: (state) => {
            state.isLoading = true;
        },
        checkSessionSuccess: (state, action) => {
            state.isLoading = false;
            state.isAuthenticated = true;
            state.user = action.payload.user;
            state.accessToken = action.payload.accessToken;
        },
        checkSessionFailure: (state) => {
            state.isLoading = false;
            state.isAuthenticated = false;
            state.user = null;
            state.accessToken = null;
        }
    }
});
export const { loginRequest, loginSuccess, loginFailure, logoutRequest, logoutSuccess, updateAccessToken, checkSessionRequest, checkSessionSuccess, checkSessionFailure } = authSlice.actions;
export default authSlice.reducer;
