var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// src/store/middleware/baseQueryWithAuth.ts
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { refreshToken } from './authThunks';
// Create a mutex to prevent multiple refresh token calls
const mutex = new Mutex();
export const EntraBaseQueryWithAuth = ({ baseUrl }) => {
    const baseQuery = fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            // Get token from state
            const token = getState().auth.accessToken;
            // If we have a token set in state, use it for authenticated requests
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        }
    });
    return (args, api, extraOptions) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        // Wait until the mutex is available without locking it
        yield mutex.waitForUnlock();
        let result = yield baseQuery(args, api, extraOptions);
        // Check if we got a 401 Unauthorized response
        if (((_a = result.error) === null || _a === void 0 ? void 0 : _a.status) === 401) {
            // Check if the mutex is locked (being refreshed by another request)
            if (!mutex.isLocked()) {
                const release = yield mutex.acquire();
                try {
                    // Try to refresh the token
                    const refreshResult = yield api.dispatch(refreshToken());
                    // If refreshing was successful, retry the original request
                    if (refreshResult.meta.requestStatus === 'fulfilled') {
                        // Retry the initial query with new access token
                        result = yield baseQuery(args, api, extraOptions);
                    }
                    else {
                        // If refreshing failed, the user might need to log in again
                        // You can handle this case by redirecting to login or dispatching a logout action
                    }
                }
                finally {
                    // Release the mutex so other requests can continue
                    release();
                }
            }
            else {
                // If the mutex is locked (another request is already refreshing the token),
                // wait for it to be unlocked and then retry the request
                yield mutex.waitForUnlock();
                result = yield baseQuery(args, api, extraOptions);
            }
        }
        return result;
    });
};
