var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const initialState = {
    bookingHistory: {
        data: [],
        total: 0,
        loading: false,
        error: null
    },
    userHistory: {
        data: [],
        total: 0,
        loading: false,
        error: null
    }
};
export const fetchBookingHistory = createAsyncThunk('audit/fetchBookingHistory', (bookingId_1, _a) => __awaiter(void 0, [bookingId_1, _a], void 0, function* (bookingId, { rejectWithValue }) {
    try {
        const response = yield fetch(`/api/audit/booking/${bookingId}`);
        if (!response.ok) {
            const errorData = yield response.json().catch(() => ({}));
            return rejectWithValue(errorData.message || `Error ${response.status}: ${response.statusText}`);
        }
        return yield response.json();
    }
    catch (error) {
        return rejectWithValue(error.message || 'An unknown error occurred');
    }
}));
export const fetchUserHistory = createAsyncThunk('audit/fetchUserHistory', (userId_1, _a) => __awaiter(void 0, [userId_1, _a], void 0, function* (userId, { rejectWithValue }) {
    try {
        const response = yield fetch(`/api/audit/user/${userId}`);
        if (!response.ok) {
            const errorData = yield response.json().catch(() => ({}));
            return rejectWithValue(errorData.message || `Error ${response.status}: ${response.statusText}`);
        }
        return yield response.json();
    }
    catch (error) {
        return rejectWithValue(error.message || 'An unknown error occurred');
    }
}));
const auditSlice = createSlice({
    name: 'audit',
    initialState,
    reducers: {
        clearBookingHistory: (state) => {
            state.bookingHistory.data = [];
            state.bookingHistory.total = 0;
        },
        clearUserHistory: (state) => {
            state.userHistory.data = [];
            state.userHistory.total = 0;
        }
    },
    extraReducers: (builder) => {
        builder
            // Booking history
            .addCase(fetchBookingHistory.pending, (state) => {
            state.bookingHistory.loading = true;
            state.bookingHistory.error = null;
        })
            .addCase(fetchBookingHistory.fulfilled, (state, action) => {
            state.bookingHistory.loading = false;
            state.bookingHistory.data = action.payload.data;
            state.bookingHistory.total = action.payload.total;
        })
            .addCase(fetchBookingHistory.rejected, (state, action) => {
            state.bookingHistory.loading = false;
            state.bookingHistory.error = action.payload;
        })
            // User history
            .addCase(fetchUserHistory.pending, (state) => {
            state.userHistory.loading = true;
            state.userHistory.error = null;
        })
            .addCase(fetchUserHistory.fulfilled, (state, action) => {
            state.userHistory.loading = false;
            state.userHistory.data = action.payload.data;
            state.userHistory.total = action.payload.total;
        })
            .addCase(fetchUserHistory.rejected, (state, action) => {
            state.userHistory.loading = false;
            state.userHistory.error = action.payload;
        });
    }
});
export const { clearBookingHistory, clearUserHistory } = auditSlice.actions;
export default auditSlice.reducer;
