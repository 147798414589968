var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useMemo } from 'react';
import { Container, Typography, Box, TextField, Button, Paper, Alert, CircularProgress, Stack, Grid, Tooltip, useTheme, alpha } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format, parse, isWithinInterval, areIntervalsOverlapping } from 'date-fns';
import { useGetCalendarEventsMutation } from '@store/services/calendarApi';
const CalendarLookup = () => {
    var _a;
    const theme = useTheme();
    const userLocale = navigator.language || 'en-US';
    // State management
    const [email, setEmail] = useState('');
    const [date, setDate] = useState(new Date());
    const [events, setEvents] = useState([]);
    const [success, setSuccess] = useState(false);
    const [timeRange, setTimeRange] = useState({ start: 8, end: 18 }); // Default 8am-6pm
    // Use RTK Query hook instead of direct fetch
    const [getCalendarEvents, { isLoading, error: apiError }] = useGetCalendarEventsMutation();
    // Form validation
    const isEmailValid = () => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const isFormValid = email && date && isEmailValid();
    // Format time for display
    const formatTime = (dateString) => {
        try {
            return format(new Date(dateString), 'h:mm a');
        }
        catch (e) {
            return dateString;
        }
    };
    const formatDate = (dateString) => {
        try {
            return new Intl.DateTimeFormat(userLocale, {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                hour: 'numeric',
                minute: '2-digit',
                hour12: userLocale !== 'en-US' ? undefined : true
            }).format(new Date(dateString));
        }
        catch (e) {
            return dateString;
        }
    };
    // Handle form submission
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        if (!isFormValid)
            return;
        try {
            // Format date for API request (YYYY-MM-DD)
            const formattedDate = date ? format(date, 'yyyy-MM-dd') : '';
            // Call the RTK Query mutation
            const response = yield getCalendarEvents({
                email,
                date: formattedDate
            }).unwrap();
            if (response.success && response.events) {
                setEvents(response.events);
                setSuccess(true);
                // Adjust visible time range based on events
                adjustTimeRange(response.events);
            }
            else {
                setEvents([]);
                setSuccess(false);
            }
        }
        catch (err) {
            console.error('Error fetching calendar data:', err);
            setEvents([]);
            setSuccess(false);
        }
    });
    // Adjust visible time range based on events
    const adjustTimeRange = (eventList) => {
        if (eventList.length === 0) {
            setTimeRange({ start: 8, end: 18 }); // Default 8am-6pm
            return;
        }
        let earliestHour = 23;
        let latestHour = 0;
        eventList.forEach((event) => {
            try {
                const startDate = new Date(event.start);
                const endDate = new Date(event.end);
                const startHour = startDate.getHours();
                const endHour = endDate.getHours() + (endDate.getMinutes() > 0 ? 1 : 0);
                earliestHour = Math.min(earliestHour, startHour);
                latestHour = Math.max(latestHour, endHour);
            }
            catch (e) {
                // Skip if date parsing fails
            }
        });
        // Add padding (1 hour before and after)
        earliestHour = Math.max(0, earliestHour - 1);
        latestHour = Math.min(23, latestHour + 1);
        setTimeRange({ start: earliestHour, end: latestHour });
    };
    // Get error message from API error
    const errorMessage = apiError
        ? ((_a = apiError === null || apiError === void 0 ? void 0 : apiError.data) === null || _a === void 0 ? void 0 : _a.message) || 'Failed to fetch calendar data. Please try again.'
        : null;
    // Generate colors for different events
    const getEventColor = (index) => {
        const colors = [
            theme.palette.primary.main,
            theme.palette.secondary.main,
            theme.palette.success.main,
            theme.palette.info.main,
            theme.palette.warning.main,
            '#9c27b0', // purple
            '#607d8b', // blue grey
            '#795548' // brown
        ];
        return colors[index % colors.length];
    };
    // Process events for display
    const processedEvents = useMemo(() => {
        if (!events.length || !date)
            return [];
        const processed = events.map((event, index) => {
            // Parse start and end times
            const startTime = new Date(event.start);
            const endTime = new Date(event.end);
            return Object.assign(Object.assign({}, event), { startTime,
                endTime, color: getEventColor(index) });
        });
        // Sort events by start time
        processed.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
        // Handle overlapping events (assign columns)
        const assignColumns = (columnEvent) => {
            if (columnEvent.length <= 1)
                return columnEvent;
            const result = [...columnEvent];
            const columns = [];
            result.forEach((event) => {
                // Find the first column where this event doesn't overlap
                let columnIndex = 0;
                // eslint-disable-next-line no-constant-condition
                while (true) {
                    if (!columns[columnIndex]) {
                        columns[columnIndex] = [];
                        break;
                    }
                    const overlapping = columns[columnIndex].some((existingEvent) => areIntervalsOverlapping({ start: existingEvent.startTime, end: existingEvent.endTime }, { start: event.startTime, end: event.endTime }));
                    if (!overlapping)
                        break;
                    columnIndex += 1;
                }
                // Assign column and add to that column's array
                event.column = columnIndex;
                columns[columnIndex].push(event);
            });
            return result;
        };
        return assignColumns(processed);
    }, [events, date, theme]);
    // Generate time slots for the calendar
    const timeSlots = useMemo(() => {
        const slots = [];
        // eslint-disable-next-line no-plusplus
        for (let hour = timeRange.start; hour <= timeRange.end; hour++) {
            slots.push(`${hour.toString().padStart(2, '0')}:00`);
            slots.push(`${hour.toString().padStart(2, '0')}:30`);
        }
        return slots;
    }, [timeRange]);
    // Check if an event should be displayed in a given time slot
    const isEventInTimeSlot = (event, timeSlot) => {
        if (!date)
            return false;
        // Parse time slot
        const [hours, minutes] = timeSlot.split(':').map(Number);
        const slotStart = new Date(date);
        slotStart.setHours(hours, minutes, 0, 0);
        const slotEnd = new Date(slotStart);
        slotEnd.setMinutes(slotStart.getMinutes() + 30);
        // Check if event overlaps with time slot
        return isWithinInterval(slotStart, { start: event.startTime, end: event.endTime })
            || isWithinInterval(slotEnd, { start: event.startTime, end: event.endTime })
            || (event.startTime <= slotStart && event.endTime >= slotEnd);
    };
    // Get all events for a specific time slot
    const getEventsForTimeSlot = (timeSlot) => processedEvents.filter((event) => isEventInTimeSlot(event, timeSlot));
    // Calculate event duration in 30-minute increments
    const getEventDurationSlots = (event) => {
        const diffMs = event.endTime.getTime() - event.startTime.getTime();
        const diffMinutes = diffMs / (1000 * 60);
        return Math.max(1, Math.ceil(diffMinutes / 30));
    };
    // Calculate event position and dimensions for the grid
    const getEventStyles = (event, firstSlotOfEvent) => {
        // Only render the event in its first time slot
        if (!firstSlotOfEvent)
            return { display: 'none' };
        const durationSlots = getEventDurationSlots(event);
        const columnCount = Math.max(1, ...processedEvents.map((e) => (e.column || 0) + 1));
        const columnWidth = 100 / columnCount;
        return {
            position: 'absolute',
            left: `${(event.column || 0) * columnWidth}%`,
            width: `${columnWidth}%`,
            height: `${durationSlots * 60 - 4}px`, // Each slot is 60px, subtract 4px for spacing
            backgroundColor: alpha(event.color, 0.2),
            borderLeft: `4px solid ${event.color}`,
            borderRadius: 4,
            overflow: 'hidden',
            zIndex: 2
        };
    };
    return (_jsx(Container, { maxWidth: "lg", children: _jsxs(Box, { sx: { my: 4 }, children: [_jsx(Typography, { variant: "h4", component: "h1", gutterBottom: true, children: "Microsoft Calendar Lookup" }), _jsx(Paper, { elevation: 3, sx: { p: 3, mb: 4 }, children: _jsx("form", { onSubmit: handleSubmit, children: _jsxs(Stack, { spacing: 3, children: [_jsx(TextField, { label: "Email Address", type: "email", fullWidth: true, value: email, onChange: (e) => setEmail(e.target.value), required: true, error: email !== '' && !isEmailValid(), helperText: email !== '' && !isEmailValid() ? 'Please enter a valid email address' : '' }), _jsx(LocalizationProvider, { dateAdapter: AdapterDateFns, children: _jsx(DatePicker, { label: "Select Date", value: date, onChange: (newDate) => setDate(newDate), slotProps: { textField: { fullWidth: true, required: true } } }) }), _jsx(Button, { type: "submit", variant: "contained", color: "primary", disabled: isLoading || !isFormValid, sx: { mt: 2 }, children: isLoading ? _jsx(CircularProgress, { size: 24, color: "inherit" }) : 'Search Calendar' })] }) }) }), errorMessage && (_jsx(Alert, { severity: "error", sx: { mb: 3 }, children: errorMessage })), success && events.length === 0 && (_jsx(Alert, { severity: "info", sx: { mb: 3 }, children: "No events found for this date." })), events.length > 0 && (_jsxs(_Fragment, { children: [_jsxs(Typography, { variant: "h5", sx: { mb: 2 }, children: ["Calendar for", ' ', date ? format(date, 'MMMM dd, yyyy') : ''] }), _jsxs(Paper, { elevation: 3, sx: { mb: 4, overflow: 'hidden' }, children: [_jsx(Box, { sx: {
                                        p: 2,
                                        backgroundColor: theme.palette.primary.main,
                                        color: theme.palette.primary.contrastText
                                    }, children: _jsx(Typography, { variant: "h6", children: date ? new Intl.DateTimeFormat(userLocale, {
                                            weekday: 'long',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                        }).format(date) : '' }) }), _jsxs(Box, { sx: { display: 'flex', height: '100%' }, children: [_jsx(Box, { sx: { width: '80px', borderRight: `1px solid ${theme.palette.divider}` }, children: timeSlots.map((slot, index) => (_jsx(Box, { sx: {
                                                    height: '60px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderBottom: index < timeSlots.length - 1 ? `1px solid ${theme.palette.divider}` : 'none',
                                                    backgroundColor: index % 2 === 0 ? alpha(theme.palette.primary.light, 0.05) : 'transparent',
                                                    padding: '0'
                                                }, children: _jsx(Typography, { variant: "caption", children: new Intl.DateTimeFormat(userLocale, {
                                                        hour: 'numeric',
                                                        minute: '2-digit',
                                                        hour12: userLocale !== 'en-US' ? undefined : true
                                                    }).format(parse(slot, 'HH:mm', new Date())) }) }, slot))) }), _jsxs(Box, { sx: { flexGrow: 1, position: 'relative' }, children: [timeSlots.map((slot, index) => (_jsx(Box, { sx: {
                                                        height: '60px',
                                                        borderBottom: index < timeSlots.length - 1 ? `1px solid ${theme.palette.divider}` : 'none',
                                                        backgroundColor: index % 2 === 0 ? alpha(theme.palette.primary.light, 0.05) : 'transparent'
                                                    } }, slot))), timeSlots.map((slot) => {
                                                    const slotEvents = getEventsForTimeSlot(slot);
                                                    return slotEvents.map((event) => {
                                                        // Only render the event at its starting time slot
                                                        const eventStartHour = event.startTime.getHours();
                                                        const eventStartMinute = event.startTime.getMinutes();
                                                        const eventStartSlot = `${eventStartHour.toString().padStart(2, '0')}:${eventStartMinute >= 30 ? '30' : '00'}`;
                                                        const isFirstSlot = slot === eventStartSlot;
                                                        if (!isFirstSlot)
                                                            return null;
                                                        // Calculate top position based on event start time
                                                        const slotIndex = timeSlots.indexOf(eventStartSlot);
                                                        const topPosition = slotIndex * 60;
                                                        const tooltipContent = (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "subtitle1", children: event.subject }), _jsxs(Typography, { variant: "body2", children: [new Intl.DateTimeFormat(userLocale, {
                                                                            hour: 'numeric',
                                                                            minute: '2-digit',
                                                                            hour12: userLocale !== 'en-US' ? undefined : true
                                                                        }).format(new Date(event.start)), "-", ' ', new Intl.DateTimeFormat(userLocale, {
                                                                            hour: 'numeric',
                                                                            minute: '2-digit',
                                                                            hour12: userLocale !== 'en-US' ? undefined : true
                                                                        }).format(new Date(event.end))] }), event.location && (_jsxs(Typography, { variant: "body2", children: ["Location:", ' ', event.location] })), event.organizer && (_jsxs(Typography, { variant: "body2", children: ["Organizer:", ' ', event.organizer, event.organizerEmail && ` (${event.organizerEmail})`] })), _jsxs(Typography, { variant: "body2", children: ["Created:", ' ', formatDate(event.createdDateTime)] })] }));
                                                        return (_jsx(Tooltip, { title: tooltipContent, arrow: true, placement: "top", children: _jsxs(Box, { sx: Object.assign(Object.assign({}, getEventStyles(event, true)), { top: topPosition, cursor: 'pointer' }), children: [_jsx(Typography, { variant: "subtitle2", noWrap: true, sx: { fontWeight: 'bold' }, children: event.subject }), _jsxs(Typography, { variant: "caption", display: "block", noWrap: true, children: [formatTime(event.start), ' ', "-", formatTime(event.end)] }), _jsxs(Typography, { variant: "caption", display: "block", noWrap: true, children: ["By:", ' ', event.organizer || 'Unknown'] }), event.location && (_jsx(Typography, { variant: "caption", display: "block", noWrap: true, children: event.location }))] }) }, `${event.id}-${slot}`));
                                                    });
                                                })] })] })] }), _jsxs(Paper, { elevation: 2, sx: { p: 2, mb: 3 }, children: [_jsx(Typography, { variant: "subtitle1", gutterBottom: true, children: "Calendar Events" }), _jsx(Grid, { container: true, spacing: 2, children: processedEvents.map((event) => (_jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, children: _jsxs(Box, { sx: {
                                                display: 'flex',
                                                flexDirection: 'column',
                                                p: 1,
                                                borderRadius: 1,
                                                backgroundColor: alpha(event.color, 0.1)
                                            }, children: [_jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [_jsx(Box, { sx: {
                                                                width: 12,
                                                                height: 12,
                                                                borderRadius: '50%',
                                                                backgroundColor: event.color,
                                                                mr: 1
                                                            } }), _jsxs(Typography, { variant: "body2", noWrap: true, sx: { fontWeight: 'bold' }, children: [event.subject, ' ', "(", formatTime(event.start), ' ', "-", formatTime(event.end), ")"] })] }), _jsxs(Box, { sx: { pl: 3 }, children: [_jsxs(Typography, { variant: "caption", display: "block", noWrap: true, color: "text.secondary", children: ["By:", ' ', event.organizer || 'Unknown'] }), _jsxs(Typography, { variant: "caption", display: "block", noWrap: true, color: "text.secondary", children: ["Created:", ' ', formatDate(event.createdDateTime)] })] })] }) }, event.id))) })] })] }))] }) }));
};
export default CalendarLookup;
