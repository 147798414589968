var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostMultipleItemsMutation } from '@store/services/payment';
import { useGetProductsQuery } from '@store/services/product';
import { useGetUsersQuery } from '@store/services/users';
import { useAppSelector, useAppDispatch } from '@store/index';
import { setCreateProductOptions } from '@store/slices/createProductSlice';
import { isNumberValue } from './Product.utils';
import { sendNotification } from '@utils';
import Checkbox from '@frontend-components/components/Checkbox';
import IconButton from '@frontend-components/components/IconButton';
import Input from '@frontend-components/components/Input';
import Button from '@frontend-components/components/Button';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { availablePurchaseTypes } from './Product.constants';
import { StyledProduct, StyledProductInfo, StyledProductCheck, StyledAddToCartBtnWrapper, StyledAddToCartBtn, StyledSelectWrapper, StyledSelectTitle, StyledProductInput, StyledEditPriceButtonWrapper, StyledEditPriceInputWrapper } from './Product.styles';
import { getUrlParam } from '@frontend-components/utils/urls';
import { Autocomplete, Grow, TextField } from '@mui/material';
const Product = () => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const userId = useAppSelector((state) => { var _a; return (_a = state.userId.payload) === null || _a === void 0 ? void 0 : _a.userId; });
    const { data: productsData } = useGetProductsQuery();
    const { data: usersData } = useGetUsersQuery({ userId: Number(userId) });
    const [additionalUser, setAdditionalUser] = useState('');
    const { selectedProduct, selectedPurchaseType, secondUser } = useAppSelector((state) => state.createProduct);
    const [rerenderKey, setRerenderKey] = useState(0);
    const [addBasketItems, { isLoading: isAddBasketItemsLoading }] = usePostMultipleItemsMutation({ fixedCacheKey: 'add-multiple-items' });
    const [selectedUsers, setSelectedUsers] = useState(userId != null ? [userId] : []);
    const [isEditingAmount, setIsEditingAmount] = useState(false);
    const [itemAmount, setItemAmount] = useState('');
    const employee = Number(getUrlParam(window.location.href, 'employee'));
    const handleClickCreateProduct = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        if (selectedUsers.length === 0) {
            sendNotification({ type: 'error', data: { message: t('bookingTheProduct.noUserSelected'), errors: [] } });
            return;
        }
        if (selectedPurchaseType === 'none' || selectedProduct === 'none') {
            sendNotification({ type: 'error', data: { message: t('bookingTheProduct.noProductSelected'), errors: [] } });
            return;
        }
        yield addBasketItems({
            userId: Number(userId),
            partnerId: (_b = (_a = usersData === null || usersData === void 0 ? void 0 : usersData.partners[0]) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '',
            employee,
            items: selectedUsers.map((user) => (Object.assign(Object.assign({ identifier: `user:${user}`, product: selectedProduct, force_add: true, editable: false, purchase_type: selectedPurchaseType }, (isEditingAmount && itemAmount.length !== 0
                ? { amount: Number(itemAmount) } : {})), { onboarded_with_id: secondUser })))
        });
        setItemAmount('');
        setIsEditingAmount(false);
        dispatch(setCreateProductOptions({
            selectedPurchaseType: 'none',
            selectedProduct: 'none'
        }));
        setRerenderKey((prevState) => prevState + 1);
    });
    const handleFirstUserCheckboxToggle = () => {
        if (userId != null && selectedUsers.includes(userId)) {
            setSelectedUsers((prevUsers) => prevUsers.filter((id) => id !== userId));
            return;
        }
        setSelectedUsers((prevUsers) => [...prevUsers, userId !== null && userId !== void 0 ? userId : 0]);
    };
    const handleSecondUserCheckboxToggle = () => {
        if ((usersData === null || usersData === void 0 ? void 0 : usersData.onboardedWithId) != null && selectedUsers.includes(usersData === null || usersData === void 0 ? void 0 : usersData.onboardedWithId)) {
            setSelectedUsers((prevUsers) => prevUsers.filter((id) => id !== (usersData === null || usersData === void 0 ? void 0 : usersData.onboardedWithId)));
            return;
        }
        setSelectedUsers((prevUsers) => { var _a; return [...prevUsers, (_a = usersData === null || usersData === void 0 ? void 0 : usersData.onboardedWithId) !== null && _a !== void 0 ? _a : 0]; });
    };
    const handleChangePurchaseType = (value) => {
        if (value !== selectedPurchaseType && value != null && value) {
            dispatch(setCreateProductOptions({
                selectedPurchaseType: value
            }));
        }
    };
    const handleChangeProduct = (value) => {
        if (value !== selectedProduct && value != null && value) {
            dispatch(setCreateProductOptions({
                selectedProduct: value
            }));
            setRerenderKey((prevState) => prevState + 1);
        }
    };
    const handleInputUserIdChange = (e) => {
        const { value } = e.currentTarget;
        if (isNumberValue(value) || value === '') {
            setSelectedUsers((prevUsers) => {
                const filteredUsers = prevUsers.filter((prevUserId) => prevUserId !== Number(additionalUser));
                if (value.length === 0)
                    return filteredUsers;
                return [...filteredUsers, Number(value)];
            });
            setAdditionalUser(value);
        }
    };
    const handleItemPriceChange = (e) => {
        const { value } = e.currentTarget;
        if (isNumberValue(value) || value === '')
            setItemAmount(value);
    };
    return (_jsxs(StyledProduct, { children: [_jsxs(StyledProductCheck, { children: [_jsx(Checkbox, { "data-cy": "first-user-checkbox", label: `${usersData === null || usersData === void 0 ? void 0 : usersData.fullname} - ${usersData === null || usersData === void 0 ? void 0 : usersData.id}`, name: t('bookingTheProduct.enduser'), disabled: isAddBasketItemsLoading, checked: userId != null && selectedUsers.includes(userId), handleOnChange: handleFirstUserCheckboxToggle }), (usersData === null || usersData === void 0 ? void 0 : usersData.onboardedWith) === true && (usersData === null || usersData === void 0 ? void 0 : usersData.onboardedWithId) != null ? (_jsx(Checkbox, { label: `${(_a = usersData === null || usersData === void 0 ? void 0 : usersData.onboardedWithFullName) !== null && _a !== void 0 ? _a : ''} - ${usersData === null || usersData === void 0 ? void 0 : usersData.onboardedWithId}`, name: t('bookingTheProduct.onBoardeduser'), checked: selectedUsers.includes(usersData.onboardedWithId), disabled: isAddBasketItemsLoading, handleOnChange: handleSecondUserCheckboxToggle })) : null, _jsx(StyledProductInput, { children: _jsx(Input, { "data-cy": "input-user-id", name: "userId", isControlledOnly: true, inputMode: "numeric", value: additionalUser, disabled: isAddBasketItemsLoading, onChange: handleInputUserIdChange, label: t('bookingTheProduct.userId') || '', placeholder: t('bookingTheProduct.userId') || '' }) })] }), _jsxs(StyledProductInfo, { children: [_jsx(StyledSelectTitle, { "data-cy": "create-product-title", children: t('bookingTheProduct.addProductForBooking') }), _jsxs(StyledSelectWrapper, { children: [_jsx(Autocomplete, { sx: {
                                    width: '75%'
                                }, id: "product-autocomplete", "data-cy": "select-product", placeholder: t('bookingTheProduct.SelectProduct') || '', options: [
                                    ...(productsData || [])
                                ], getOptionLabel: (option) => (typeof option === 'string'
                                    ? option
                                    : option.title), value: (productsData === null || productsData === void 0 ? void 0 : productsData.find((p) => p.machineName === selectedProduct)) || null, onChange: (_, newValue) => {
                                    handleChangeProduct(newValue === null || newValue === void 0 ? void 0 : newValue.machineName);
                                }, disabled: isAddBasketItemsLoading, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: t('bookingTheProduct.SelectProduct'), name: t('bookingTheProduct.productTypes', 'PRODUKT') || 'Produkt', inputProps: Object.assign({}, params.inputProps) }))), renderOption: (props, option) => (_createElement("li", Object.assign({}, props, { key: option.machineName, "data-cy": `select-product-option-${option.machineName}` }), option.title)), isOptionEqualToValue: (option, value) => option.machineName === value.machineName }), _jsx(Autocomplete, { sx: {
                                    width: '75%'
                                }, id: "purchase-type-autocomplete", "data-cy": "purchase-type-select", placeholder: t('bookingTheProduct.selectPurchaseType') || '', options: [
                                    ...(((_b = productsData === null || productsData === void 0 ? void 0 : productsData.find((product) => product.machineName === selectedProduct)) === null || _b === void 0 ? void 0 : _b.purchase_types.filter((type) => availablePurchaseTypes.includes(type)).map((purchaseType) => ({
                                        value: purchaseType,
                                        label: t(`global.purchaseTypes.${purchaseType}`)
                                    }))) || [])
                                ], getOptionLabel: (option) => (typeof option === 'string'
                                    ? option
                                    : option.label), value: selectedPurchaseType === 'none' || !selectedPurchaseType
                                    ? { value: 'none', label: t('bookingTheProduct.selectPurchaseType') }
                                    : {
                                        value: selectedPurchaseType,
                                        label: t(`global.purchaseTypes.${selectedPurchaseType}`)
                                    }, onChange: (_, newValue) => {
                                    handleChangePurchaseType((newValue === null || newValue === void 0 ? void 0 : newValue.value) || 'none');
                                }, disabled: isAddBasketItemsLoading || !selectedProduct || selectedProduct === 'none', renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: t('bookingTheProduct.selectPurchaseType'), name: t('bookingTheProduct.purchaseTypes') || '?', inputProps: Object.assign({}, params.inputProps) }))), renderOption: (props, option) => (_createElement("li", Object.assign({}, props, { key: option.value, "data-cy": `purchase-type-select-option-${option.value}` }), option.label)), isOptionEqualToValue: (option, value) => option.value === value.value }, rerenderKey)] }), _jsx(StyledEditPriceButtonWrapper, { children: !isEditingAmount ? _jsx(Button, { kind: "text", onClick: () => setIsEditingAmount(true), children: t('bookingTheProduct.editItemPrice') })
                            : (_jsx(Grow, { in: isEditingAmount, children: _jsxs(StyledEditPriceInputWrapper, { children: [_jsx(Input, { label: t('bookingTheProduct.editItemPrice'), isControlledOnly: true, placeholder: (_c = t('bookingTheProduct.enterAmount')) !== null && _c !== void 0 ? _c : '', onChange: handleItemPriceChange, value: itemAmount, name: "item-amount" }), _jsx(IconButton, { icon: faClose, style: { width: '17px', height: '17px' }, title: "Cancel", onClick: () => setIsEditingAmount(false) })] }) })) })] }), _jsx(StyledAddToCartBtnWrapper, { children: _jsx(StyledAddToCartBtn, { "data-cy": "add-to-cart-button", color: "pink", onClick: handleClickCreateProduct, isLoading: isAddBasketItemsLoading, children: t('bookingTheProduct.addtoCart') }) })] }));
};
export default Product;
