import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import { getUrlParam } from '@frontend-components/utils/urls/urls';
// Create context with default values
const EcommerceFlagContext = createContext({
    isEcommerceEnabled: false,
    toggleEcommerceVersion: () => { },
    resetToDefaultVersion: () => { },
    isOverridden: false,
    ecommerceFlagFromUnleash: false
});
// Custom hook to use the context
export const useEcommerceFlag = () => useContext(EcommerceFlagContext);
export const EcommerceFlagProvider = ({ children }) => {
    // Get the original flag value from Unleash
    const ecommerceFlagFromUnleash = useFlag('EcommerceON');
    // State to track the effective flag value (can be overridden)
    const [isEcommerceEnabled, setIsEcommerceEnabled] = useState(false);
    // Track if user has manually overridden the flag
    const [isOverridden, setIsOverridden] = useState(false);
    // Initialize flag value considering URL parameter and stored preference
    useEffect(() => {
        // Check URL parameter first
        const oldParamValue = getUrlParam(window.location.href, 'old');
        const useOldVersion = oldParamValue === 'true';
        // Check localStorage for saved preference
        const storedPreference = localStorage.getItem('useEcommerceVersion');
        const hasStoredPreference = storedPreference !== null;
        if (oldParamValue) {
            // URL parameter takes precedence, save to localStorage
            const newValue = !useOldVersion;
            setIsEcommerceEnabled(newValue);
            setIsOverridden(true);
            localStorage.setItem('useEcommerceVersion', String(newValue));
        }
        else if (hasStoredPreference) {
            // Use stored preference if available
            setIsEcommerceEnabled(storedPreference === 'true');
            setIsOverridden(true);
        }
        else {
            // Default to the Unleash flag value
            setIsEcommerceEnabled(ecommerceFlagFromUnleash);
            setIsOverridden(false);
        }
    }, [ecommerceFlagFromUnleash]);
    // Function to toggle between versions
    const toggleEcommerceVersion = () => {
        const newValue = !isEcommerceEnabled;
        setIsEcommerceEnabled(newValue);
        setIsOverridden(true);
        localStorage.setItem('useEcommerceVersion', String(newValue));
    };
    // Function to reset to the Unleash flag value
    const resetToDefaultVersion = () => {
        setIsEcommerceEnabled(ecommerceFlagFromUnleash);
        setIsOverridden(false);
        localStorage.removeItem('useEcommerceVersion');
    };
    const value = useMemo(() => ({
        isEcommerceEnabled,
        toggleEcommerceVersion,
        resetToDefaultVersion,
        isOverridden,
        ecommerceFlagFromUnleash
    }), [
        isEcommerceEnabled,
        toggleEcommerceVersion,
        resetToDefaultVersion,
        isOverridden,
        ecommerceFlagFromUnleash
    ]);
    return _jsx(EcommerceFlagContext.Provider, { value: value, children: children });
};
