import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { format, parseISO } from 'date-fns';
import { Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip, Alert, CircularProgress, Button, Card, CardHeader, CardContent, Divider } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import WarningIcon from '@mui/icons-material/Warning';
const DoubleBookings = ({ bookings, isLoading, error, onRowClick, onRefresh }) => {
    const formatDateTime = (dateStr) => {
        try {
            return format(parseISO(dateStr), 'MMM d, yyyy HH:mm');
        }
        catch (e) {
            return dateStr;
        }
    };
    const getStatusColor = (statusId) => {
        switch (statusId) {
            case 1: // Created
                return 'primary';
            case 5: // Confirmed
                return 'success';
            default:
                return 'default';
        }
    };
    return (_jsxs(Card, { elevation: 3, children: [_jsx(CardHeader, { title: (_jsxs(Box, { display: "flex", alignItems: "center", children: [_jsx(WarningIcon, { color: "error", sx: { mr: 1 } }), _jsx(Typography, { variant: "h5", children: "Double Bookings" })] })), action: (_jsx(Button, { variant: "outlined", startIcon: _jsx(RefreshIcon, {}), onClick: onRefresh, disabled: isLoading, children: "Refresh" })), subheader: `${bookings.length} double bookings found` }), _jsx(Divider, {}), _jsx(CardContent, { children: isLoading ? (_jsx(Box, { display: "flex", justifyContent: "center", alignItems: "center", p: 3, children: _jsx(CircularProgress, {}) })) : error ? (_jsx(Alert, { severity: "error", children: error })) : bookings.length === 0 ? (_jsx(Alert, { severity: "info", children: "No double bookings found." })) : (_jsx(TableContainer, { component: Paper, variant: "outlined", children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "ID" }), _jsx(TableCell, { children: "Status" }), _jsx(TableCell, { children: "Employee" }), _jsx(TableCell, { children: "Start Time" }), _jsx(TableCell, { children: "End Time" }), _jsx(TableCell, { children: "Duration" }), _jsx(TableCell, { children: "Created" })] }) }), _jsx(TableBody, { children: bookings.map((booking) => {
                                    var _a;
                                    return (_jsxs(TableRow, { sx: {
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            cursor: onRowClick ? 'pointer' : 'default',
                                            '&:hover': {
                                                backgroundColor: (theme) => theme.palette.action.hover
                                            }
                                        }, onClick: () => onRowClick && onRowClick(booking.id), children: [_jsx(TableCell, { component: "th", scope: "row", children: booking.id }), _jsx(TableCell, { children: _jsx(Chip, { label: booking.status.name, color: getStatusColor(booking.status.id), size: "small" }) }), _jsx(TableCell, { children: ((_a = booking.date) === null || _a === void 0 ? void 0 : _a.employee_name) || 'N/A' }), _jsx(TableCell, { children: booking.date ? formatDateTime(booking.date.start_date) : 'N/A' }), _jsx(TableCell, { children: booking.date ? formatDateTime(booking.date.end_date) : 'N/A' }), _jsxs(TableCell, { children: [booking.minutes, ' ', "min"] }), _jsx(TableCell, { children: formatDateTime(booking.created_at) })] }, booking.id));
                                }) })] }) })) })] }));
};
export default DoubleBookings;
