import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CssBaseline } from '@mui/material';
import StaffScheduleViewer from '../StaffScheduleViewer';
const drawerWidth = 1;
const StaffSchedulePage = () => (_jsxs(Box, { sx: { display: 'flex' }, children: [_jsx(CssBaseline, {}), _jsx(Box, { component: "main", sx: {
                flexGrow: 1,
                p: 3,
                width: { md: `calc(100% - ${drawerWidth}px)` },
                marginTop: '0' // Toolbar height
            }, children: _jsx(StaffScheduleViewer, {}) })] }));
export default StaffSchedulePage;
