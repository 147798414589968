import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CssBaseline, Container } from '@mui/material';
import AuditHistory from './AuditHistory';
const BookingHistoryPage = () => (_jsxs(Box, { sx: { display: 'flex' }, children: [_jsx(CssBaseline, {}), _jsx(Box, { component: "main", sx: {
                flexGrow: 1,
                p: 3,
                width: '100%',
                marginTop: '0'
            }, children: _jsx(Container, { maxWidth: "xl", children: _jsx(AuditHistory, { type: "booking" }) }) })] }));
export default BookingHistoryPage;
