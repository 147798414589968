import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Container, Typography, Box, Grid, Card, IconButton, Tooltip, AppBar, Toolbar, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import RefreshIcon from '@mui/icons-material/Refresh';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useGetDoubleBookingsQuery } from '@store/services/doubleBookingAPI';
import DoubleBookings from './DoubleBookings';
import DoubleBookingDetail from './DoubleBookingDetails';
const StyledAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1]
}));
const StatsCard = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    position: 'relative',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: theme.shadows[10]
    }
}));
const DoubleBookingsDashboard = () => {
    var _a;
    const [selectedBookingId, setSelectedBookingId] = useState(null);
    // Using RTK Query hook instead of traditional Redux
    const { data: doubleBookingsResponse, isLoading, isFetching, error, refetch } = useGetDoubleBookingsQuery();
    const handleRefresh = () => {
        refetch();
    };
    const handleRowClick = (bookingId) => {
        setSelectedBookingId(bookingId);
    };
    const handleCloseDetail = () => {
        setSelectedBookingId(null);
    };
    // Derived data
    const bookings = (doubleBookingsResponse === null || doubleBookingsResponse === void 0 ? void 0 : doubleBookingsResponse.data) || [];
    const total = ((_a = doubleBookingsResponse === null || doubleBookingsResponse === void 0 ? void 0 : doubleBookingsResponse.meta) === null || _a === void 0 ? void 0 : _a.total) || 0;
    const isLoaded = !isLoading && doubleBookingsResponse;
    // Example of grouping by employee
    const bookingsByEmployee = isLoaded
        ? bookings.reduce((acc, booking) => {
            var _a;
            const employeeName = ((_a = booking.date) === null || _a === void 0 ? void 0 : _a.employee_name) || 'Unknown';
            if (!acc[employeeName]) {
                acc[employeeName] = [];
            }
            acc[employeeName].push(booking);
            return acc;
        }, {})
        : {};
    const employeesWithConflicts = Object.keys(bookingsByEmployee).length;
    return (_jsxs(Box, { sx: { flexGrow: 1 }, children: [_jsx(StyledAppBar, { position: "static", children: _jsxs(Toolbar, { children: [_jsx(CalendarMonthIcon, { sx: { mr: 2 } }), _jsx(Typography, { variant: "h6", component: "div", sx: { flexGrow: 1 }, children: "Double Bookings Dashboard" }), _jsx(Tooltip, { title: "Refresh data", children: _jsx(IconButton, { color: "inherit", onClick: handleRefresh, disabled: isFetching, children: isFetching ? _jsx(CircularProgress, { size: 24, color: "inherit" }) : _jsx(RefreshIcon, {}) }) })] }) }), _jsx(Container, { maxWidth: "lg", sx: { mt: 4, mb: 4 }, children: _jsxs(Grid, { container: true, spacing: 3, children: [_jsx(Grid, { item: true, xs: 12, md: 4, children: _jsxs(StatsCard, { children: [_jsx(WarningAmberIcon, { color: "error", sx: { fontSize: 40, mb: 1 } }), _jsx(Typography, { variant: "h4", children: isLoaded ? total : '-' }), _jsx(Typography, { variant: "subtitle1", color: "text.secondary", children: "Total Double Bookings" })] }) }), _jsx(Grid, { item: true, xs: 12, md: 4, children: _jsxs(StatsCard, { children: [_jsx(CalendarMonthIcon, { color: "primary", sx: { fontSize: 40, mb: 1 } }), _jsx(Typography, { variant: "h4", children: isLoaded ? employeesWithConflicts : '-' }), _jsx(Typography, { variant: "subtitle1", color: "text.secondary", children: "Employees Affected" })] }) }), _jsx(Grid, { item: true, xs: 12, md: 4, children: _jsxs(StatsCard, { children: [_jsxs(Box, { sx: {
                                            position: 'relative', height: 40, width: 40, mb: 1
                                        }, children: [_jsx(Box, { sx: {
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: 24,
                                                    height: 24,
                                                    bgcolor: 'error.main',
                                                    borderRadius: '50%'
                                                } }), _jsx(Box, { sx: {
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    right: 0,
                                                    width: 24,
                                                    height: 24,
                                                    bgcolor: 'warning.main',
                                                    borderRadius: '50%'
                                                } })] }), _jsx(Typography, { variant: "h4", children: isLoaded ? bookings.length : '-' }), _jsx(Typography, { variant: "subtitle1", color: "text.secondary", children: "Active Conflicting Bookings" })] }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(DoubleBookings, { bookings: bookings, isLoading: isLoading, error: error ? 'Error fetching data' : null, onRowClick: handleRowClick, onRefresh: handleRefresh }) })] }) }), _jsx(DoubleBookingDetail, { selectedBookingId: selectedBookingId, onClose: handleCloseDetail })] }));
};
export default DoubleBookingsDashboard;
