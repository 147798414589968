var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Card, CardContent, Typography, Container, useTheme, Alert, Fade, alpha, Divider, CircularProgress } from '@mui/material';
import { LoginRounded } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '@store/index';
import { selectIsAuthenticated, selectIsLoading, selectAuthError } from '@store/entra-auth/authSelectors';
import { login } from '@store/entra-auth/authThunks'; // Import the login thunk
const LoginPage = () => {
    var _a, _b;
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    // Get auth state from Redux
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const isLoading = useAppSelector(selectIsLoading);
    const authError = useAppSelector(selectAuthError);
    // Local error state for UI
    const [localError, setLocalError] = useState(null);
    // Get the redirect path from location state or default to '/'
    const from = ((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.from) === null || _b === void 0 ? void 0 : _b.pathname) || '/overview';
    // Handle successful authentication redirect
    useEffect(() => {
        if (isAuthenticated) {
            navigate(from, { replace: true });
        }
    }, [isAuthenticated, navigate, from]);
    // Use the login thunk instead of direct action dispatching
    const handleLogin = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLocalError(null);
            // Use the thunk which handles all the login logic in one place
            yield dispatch(login());
        }
        catch (err) {
            console.error('Login failed:', err);
            setLocalError('Login fejlede. Prøv venligst igen.');
        }
    });
    // Combined error message (from Redux or local state)
    const errorMessage = authError || localError;
    return (_jsx(Container, { component: "main", maxWidth: "sm", sx: {
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            py: 4,
            backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.light, 0.1)} 0%, ${alpha(theme.palette.primary.main, 0.1)} 100%)`
        }, children: _jsxs(Box, { sx: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }, children: [_jsx(Box, { sx: {
                        mb: 4,
                        width: '220px',
                        height: 'auto',
                        display: 'flex',
                        justifyContent: 'center'
                    }, children: _jsx("img", { src: "https://testaviva.dk/wp-content/uploads/2024/04/TVlogo.png", alt: "TESTAVIVA", style: { width: '100%', height: 'auto' } }) }), _jsx(Card, { elevation: 8, sx: {
                        width: '100%',
                        maxWidth: 'sm',
                        p: { xs: 2, sm: 4 },
                        borderRadius: 2,
                        backgroundColor: theme.palette.background.paper,
                        boxShadow: `0 10px 40px ${alpha(theme.palette.primary.dark, 0.15)}`
                    }, children: _jsx(CardContent, { children: _jsxs(Box, { sx: {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 3
                            }, children: [_jsx(Box, { sx: {
                                        backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                        borderRadius: '50%',
                                        p: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }, children: _jsx(LoginRounded, { fontSize: "large", sx: {
                                            color: theme.palette.primary.main,
                                            fontSize: 40
                                        } }) }), _jsx(Typography, { component: "h1", variant: "h4", align: "center", sx: {
                                        mb: 1,
                                        fontWeight: 'bold',
                                        color: theme.palette.text.primary
                                    }, children: "Velkommen tilbage" }), _jsx(Typography, { variant: "body1", color: "text.secondary", align: "center", sx: { mb: 2 }, children: "Log ind med din virksomhedskonto for at forts\u00E6tte" }), errorMessage && (_jsx(Fade, { in: true, children: _jsx(Alert, { severity: "error", sx: { width: '100%' }, onClose: () => setLocalError(null), children: errorMessage }) })), _jsx(Button, { variant: "contained", size: "large", onClick: handleLogin, startIcon: isLoading ? _jsx(CircularProgress, { size: 20, color: "inherit" }) : _jsx(LoginRounded, {}), disabled: isLoading, sx: {
                                        width: '100%',
                                        py: 1.5,
                                        textTransform: 'none',
                                        fontSize: '1.1rem',
                                        backgroundColor: theme.palette.primary.main,
                                        borderRadius: 1,
                                        boxShadow: '0 4px 12px rgba(0, 79, 84, 0.3)',
                                        '&:hover': {
                                            backgroundColor: theme.palette.primary.dark,
                                            boxShadow: '0 6px 16px rgba(0, 79, 84, 0.4)'
                                        }
                                    }, children: isLoading ? 'Logger ind...' : 'Log ind med Microsoft' }), _jsx(Divider, { sx: { width: '100%', my: 1 } }), _jsx(Typography, { variant: "caption", color: "text.secondary", align: "center", children: "\u00A9 2025 TESTAVIVA. Ved at logge ind accepterer du vores vilk\u00E5r og betingelser." })] }) }) })] }) }));
};
export default LoginPage;
