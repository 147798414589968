// Default values for development
const defaultConfig = {
    UNLEASH_PROXY_URL: 'https://unleash.local.testaviva.dev/api/frontend',
    UNLEASH_CLIENT_KEY: 'default:development.unleash-insecure-frontend-api-token',
    ENVIRONMENT: 'development',
    ECOMMERCE_URL: 'https://ecommerce.local.testaviva.dev'
};
// Helper function to check if a variable exists in window.ENV
const getConfigValue = (key) => {
    if (typeof window !== 'undefined' && window.ENV && window.ENV[key] !== undefined) {
        return window.ENV[key];
    }
    return defaultConfig[key];
};
// Export a config object that selectively uses window.ENV values when available
export const config = {
    UNLEASH_PROXY_URL: getConfigValue('UNLEASH_PROXY_URL'),
    UNLEASH_CLIENT_KEY: getConfigValue('UNLEASH_CLIENT_KEY'),
    ENVIRONMENT: getConfigValue('ENVIRONMENT'),
    ECOMMERCE_URL: getConfigValue('ECOMMERCE_URL')
};
