var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable */
import { PublicClientApplication, EventType } from '@azure/msal-browser';
// MSAL configuration
export const msalConfig = {
    auth: {
        clientId: '09a6d02e-7823-4f87-b753-947f0a0bf0fe',
        authority: 'https://login.microsoftonline.com/d4ebe44b-4256-4f0d-b490-fd95cd0e9a92',
        redirectUri: window.location.origin
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    }
};
// Scopes for login
export const loginRequest = {
    scopes: ['User.Read']
};
// Create a single MSAL instance to be used throughout the app
export const msalInstance = new PublicClientApplication(msalConfig);
// Track initialization state
let _initialized = false;
// Initialize MSAL instance - must be called before using MSAL
export const initializeMsal = () => __awaiter(void 0, void 0, void 0, function* () {
    if (_initialized) {
        return true;
    }
    try {
        // Initialize MSAL
        yield msalInstance.initialize();
        console.log('MSAL initialized successfully');
        // Register event listeners only after initialization
        msalInstance.addEventCallback((event) => {
            if (event.eventType === EventType.LOGIN_SUCCESS) {
                console.log('Login successful');
            }
            else if (event.eventType === EventType.LOGOUT_SUCCESS) {
                console.log('Logout successful');
            }
            else if (event.eventType === EventType.LOGIN_FAILURE) {
                console.error('Login failed:', event.error);
            }
        });
        // Handle redirect ONLY after initialization
        try {
            yield msalInstance.handleRedirectPromise();
        }
        catch (redirectError) {
            console.error('Error handling redirect:', redirectError);
            // Don't throw this error, as it might just mean there was no redirect to handle
        }
        _initialized = true;
        return true;
    }
    catch (error) {
        console.error('MSAL initialization failed:', error);
        return false;
    }
});
// Export a function to check if MSAL is initialized
export const isMsalInitialized = () => _initialized;
