var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
export const fetchStaff = createAsyncThunk('staff/fetchStaff', () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetch('/api/staff-availability');
    const data = yield response.json();
    if (data.status === 'error')
        throw new Error(data.message);
    return data.data;
}));
// Update the updateStaffAvailability thunk
export const updateStaffAvailability = createAsyncThunk('staff/updateAvailability', (_a) => __awaiter(void 0, [_a], void 0, function* ({ staffId, startDate, endDate }) {
    const response = yield fetch(`/api/staff-availability/${staffId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            start_date: startDate.toISOString().split('T')[0],
            end_date: endDate.toISOString().split('T')[0]
        })
    });
    const data = yield response.json();
    if (data.status === 'error')
        throw new Error(data.message);
}));
export const fetchStaffAvailability = createAsyncThunk('staff/fetchAvailability', (staffId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetch(`/api/staff-availability/${staffId}`);
    const data = yield response.json();
    if (data.status === 'error')
        throw new Error(data.message);
    return data.data;
}));
const initialState = {
    staff: [],
    selectedStaff: null,
    availability: null,
    loading: false,
    error: null,
    updateStatus: {}
};
const staffSlice = createSlice({
    name: 'staff',
    initialState,
    reducers: {
        selectStaff: (state, action) => {
            state.selectedStaff = action.payload;
        },
        clearError: (state) => {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStaff.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
            .addCase(fetchStaff.fulfilled, (state, action) => {
            state.loading = false;
            state.staff = action.payload;
        })
            .addCase(fetchStaff.rejected, (state, action) => {
            var _a;
            state.loading = false;
            state.error = (_a = action.error.message) !== null && _a !== void 0 ? _a : 'Failed to fetch staff';
        })
            .addCase(updateStaffAvailability.pending, (state, action) => {
            const { staffId } = action.meta.arg;
            state.updateStatus[staffId] = 'loading';
            state.error = null;
        })
            .addCase(updateStaffAvailability.fulfilled, (state, action) => {
            const { staffId } = action.meta.arg;
            state.updateStatus[staffId] = 'succeeded';
        })
            .addCase(updateStaffAvailability.rejected, (state, action) => {
            var _a;
            const { staffId } = action.meta.arg;
            state.updateStatus[staffId] = 'failed';
            state.error = (_a = action.error.message) !== null && _a !== void 0 ? _a : 'Failed to update availability';
        });
    }
});
export const { selectStaff, clearError } = staffSlice.actions;
export default staffSlice.reducer;
