var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Chip, CircularProgress, Alert, Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useGetAllStaffQuery, useUpdateStaffAvailabilityMutation } from '@store/services/staffApi';
export const StaffAvailabilityList = () => {
    var _a;
    // Use RTK Query hooks
    const { data: staff = [], isLoading, error, refetch } = useGetAllStaffQuery();
    const [updateStaffAvailability, { isLoading: isUpdating }] = useUpdateStaffAvailabilityMutation();
    // Local state for date ranges
    const [dateRanges, setDateRanges] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const handleDateChange = (staffId, field, value) => {
        setDateRanges((prev) => (Object.assign(Object.assign({}, prev), { [staffId]: Object.assign(Object.assign({}, prev[staffId]), { [field]: value }) })));
    };
    const handleUpdate = (staffMember) => __awaiter(void 0, void 0, void 0, function* () {
        const dates = dateRanges[staffMember.id];
        if (!(dates === null || dates === void 0 ? void 0 : dates.startDate) || !(dates === null || dates === void 0 ? void 0 : dates.endDate)) {
            enqueueSnackbar('Please select both start and end dates', {
                variant: 'error',
                persist: false,
                style: {
                    width: '400px', // Fixed width for better visibility
                    textAlign: 'center'
                }
            });
            return;
        }
        try {
            // Format dates for API request
            const startDate = dates.startDate.format('YYYY-MM-DD');
            const endDate = dates.endDate.format('YYYY-MM-DD');
            // Call the RTK Query mutation
            yield updateStaffAvailability({
                staffId: staffMember.id,
                start_date: startDate,
                end_date: endDate
            }).unwrap();
            enqueueSnackbar('Staff availability update initiated successfully', {
                variant: 'success'
            });
            // Refetch staff list to get updated status
            refetch();
        }
        catch (err) {
            enqueueSnackbar('Failed to update staff availability', {
                variant: 'error'
            });
        }
    });
    if (isLoading) {
        return (_jsx(Box, { display: "flex", justifyContent: "center", padding: 4, children: _jsx(CircularProgress, {}) }));
    }
    if (error) {
        return (_jsxs(Alert, { severity: "error", sx: { margin: 2 }, children: [((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.message) || 'Failed to load staff data', _jsx(Button, { variant: "outlined", size: "small", sx: { marginLeft: 2 }, onClick: () => refetch(), children: "Retry" })] }));
    }
    return (_jsx(LocalizationProvider, { dateAdapter: AdapterDayjs, children: _jsxs(Box, { sx: { padding: 2 }, children: [_jsx(Typography, { variant: "h5", gutterBottom: true, children: "Staff Availability Management" }), _jsx(TableContainer, { component: Paper, children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Name" }), _jsx(TableCell, { children: "Email" }), _jsx(TableCell, { children: "Last Update" }), _jsx(TableCell, { children: "Status" }), _jsx(TableCell, { children: "Start Date" }), _jsx(TableCell, { children: "End Date" }), _jsx(TableCell, { children: "Actions" })] }) }), _jsx(TableBody, { children: staff.map((staffMember) => {
                                    const dates = dateRanges[staffMember.id];
                                    const isUpdatingThis = isUpdating;
                                    return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: staffMember.name }), _jsx(TableCell, { children: staffMember.email }), _jsx(TableCell, { children: staffMember.last_availability_update
                                                    ? new Date(staffMember.last_availability_update).toLocaleString()
                                                    : 'Never' }), _jsx(TableCell, { children: _jsx(Chip, { label: staffMember.availability_status, color: staffMember.availability_status === 'processed' ? 'success' : 'warning' }) }), _jsx(TableCell, { children: _jsx(DatePicker, { value: (dates === null || dates === void 0 ? void 0 : dates.startDate) || null, onChange: (newValue) => handleDateChange(staffMember.id, 'startDate', newValue), slotProps: { textField: { size: 'small' } } }) }), _jsx(TableCell, { children: _jsx(DatePicker, { value: (dates === null || dates === void 0 ? void 0 : dates.endDate) || null, onChange: (newValue) => handleDateChange(staffMember.id, 'endDate', newValue), slotProps: { textField: { size: 'small' } } }) }), _jsx(TableCell, { children: _jsx(Button, { variant: "contained", color: "primary", onClick: () => handleUpdate(staffMember), disabled: isUpdatingThis || !(dates === null || dates === void 0 ? void 0 : dates.startDate) || !(dates === null || dates === void 0 ? void 0 : dates.endDate), children: isUpdatingThis ? (_jsx(CircularProgress, { size: 24, color: "inherit" })) : ('Update Availability') }) })] }, staffMember.id));
                                }) })] }) })] }) }));
};
