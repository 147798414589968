import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useEcommerceFlag } from '@hooks/useEcommerceFlag';
import { Switch, FormControlLabel, Typography, Box } from '@mui/material';
const EcommerceToggle = () => {
    const { t } = useTranslation();
    const { isEcommerceEnabled, toggleEcommerceVersion, ecommerceFlagFromUnleash } = useEcommerceFlag();
    if (!ecommerceFlagFromUnleash) {
        return null;
    }
    return (_jsx(Box, { sx: {
            width: '100%',
            bgcolor: isEcommerceEnabled ? 'success.main' : 'warning.main',
            color: isEcommerceEnabled ? 'success.contrastText' : 'warning.contrastText',
            boxShadow: 'none'
        }, children: _jsxs(Box, { display: "flex", alignItems: "center", justifyContent: "space-between", sx: { p: 1 }, children: [_jsx(Typography, { variant: "body1", children: isEcommerceEnabled
                        ? t('ecommerce.usingNewVersion', 'Using new ecommerce version')
                        : t('ecommerce.usingOldVersion', 'Using old version') }), _jsx(Box, { display: "flex", alignItems: "center", children: _jsx(FormControlLabel, { control: (_jsx(Switch, { checked: isEcommerceEnabled, onChange: toggleEcommerceVersion, color: "default", "data-cy": "ecommerce-toggle" })), label: (_jsx(Typography, { variant: "body2", children: isEcommerceEnabled
                                ? t('ecommerce.useOldVersion', 'Switch to old version')
                                : t('ecommerce.useNewVersion', 'Switch to new version') })) }) })] }) }));
};
export default EcommerceToggle;
