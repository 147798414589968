import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import AuthProvider from '@hooks/AuthProvider';
import { Provider } from 'react-redux';
import { store } from './src/store';
import ErrorBoundary from '@frontend-components/components/ErrorBoundary';
import App from './src/App';
import { GlobalStyle } from './src/global.styles';
import '@frontend-components/css/global.scss';
import './i18n';
import FlagProvider from '@unleash/proxy-client-react';
import { unleashConfig } from './unleash';
const BookingSpa = () => (_jsx(StrictMode, { children: _jsx(ErrorBoundary, { children: _jsx(FlagProvider, { config: unleashConfig, children: _jsx(Provider, { store: store, children: _jsxs(AuthProvider, { children: [_jsx(GlobalStyle, {}), _jsx(App, {})] }) }) }) }) }));
const targetELement = document.getElementById('booking-spa-wrapper');
if (targetELement != null) {
    const root = createRoot(targetELement);
    root.render(_jsx(BookingSpa, {}));
}
