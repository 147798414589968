var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { useAppDispatch } from '@store/index';
import { checkSession, initializeAuth } from '@store/entra-auth/authThunks';
import { msalInstance } from '../MSALConfig';
import { Button } from '@mui/material';
const AuthProvider = ({ children }) => {
    const dispatch = useAppDispatch();
    const [isInitialized, setIsInitialized] = useState(false);
    const [initError, setInitError] = useState(null);
    useEffect(() => {
        const initialize = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                // Initialize MSAL first
                yield dispatch(initializeAuth());
                // Once MSAL is initialized, check the session
                yield dispatch(checkSession());
                // Mark initialization as complete
                setIsInitialized(true);
            }
            catch (error) {
                console.error('Authentication initialization failed:', error);
                setInitError(error instanceof Error ? error.message : 'Failed to initialize authentication');
                // Still mark as initialized so the app can show an error screen
                setIsInitialized(true);
            }
        });
        initialize();
    }, [dispatch]);
    // Show loading state while initializing
    if (!isInitialized) {
        return (_jsx("div", { style: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                flexDirection: 'column'
            }, children: _jsx("div", { style: { marginBottom: '20px' }, children: "Initializing authentication..." }) }));
    }
    // Show error state if initialization failed
    if (initError) {
        return (_jsxs("div", { style: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                flexDirection: 'column',
                color: 'red'
            }, children: [_jsx("div", { style: { marginBottom: '20px' }, children: "Authentication Error" }), _jsx("div", { children: initError }), _jsx(Button, { onClick: () => window.location.reload(), style: {
                        marginTop: '20px',
                        padding: '10px 15px',
                        borderRadius: '4px',
                        border: 'none',
                        backgroundColor: '#1976d2',
                        color: 'white',
                        cursor: 'pointer'
                    }, children: "Retry" })] }));
    }
    // Render children with MsalProvider when everything is initialized
    return (_jsx(MsalProvider, { instance: msalInstance, children: children }));
};
export default AuthProvider;
