var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const initialState = {
    bookings: [],
    total: 0,
    loading: false,
    error: null
};
export const fetchDoubleBookings = createAsyncThunk('doubleBookings/fetchDoubleBookings', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { rejectWithValue }) {
    try {
        const response = yield fetch('/api/audit/double-bookings');
        if (!response.ok) {
            const errorData = yield response.json().catch(() => ({}));
            return rejectWithValue(errorData.message || `Error ${response.status}: ${response.statusText}`);
        }
        return yield response.json();
    }
    catch (error) {
        return rejectWithValue(error.message || 'An unknown error occurred');
    }
}));
const doubleBookingsSlice = createSlice({
    name: 'doubleBookings',
    initialState,
    reducers: {
        clearDoubleBookings: (state) => {
            state.bookings = [];
            state.total = 0;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDoubleBookings.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
            .addCase(fetchDoubleBookings.fulfilled, (state, action) => {
            state.loading = false;
            state.bookings = action.payload.data;
            state.total = action.payload.meta.total;
        })
            .addCase(fetchDoubleBookings.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});
export const { clearDoubleBookings } = doubleBookingsSlice.actions;
export default doubleBookingsSlice.reducer;
