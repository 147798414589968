var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../middleware/baseQueryWithAuth';
import { bookingApiUrl } from '@constants';
import { productsToParams } from './utils';
export const BOOKING_API_AVAILABILITY_KEY = 'availabilityApi';
export const availabilityApi = createApi({
    reducerPath: BOOKING_API_AVAILABILITY_KEY,
    baseQuery: baseQueryWithAuth({ baseUrl: bookingApiUrl }),
    keepUnusedDataFor: 15,
    refetchOnFocus: true,
    endpoints: (builder) => ({
        getEnabledDays: builder.query({
            query: (_a) => {
                var { products } = _a, otherParams = __rest(_a, ["products"]);
                const productsParams = products && productsToParams(products);
                return ({
                    url: '/api/bookings/timeslot/enabled-days',
                    method: 'GET',
                    params: Object.assign(Object.assign({}, productsParams), otherParams)
                });
            }
        }),
        postNewBooking: builder.mutation({
            query: (body) => ({
                url: '/api/bookings',
                method: 'POST',
                body
            })
        }),
        putUpdateBooking: builder.mutation({
            query: (_a) => {
                var { bookingId } = _a, body = __rest(_a, ["bookingId"]);
                return ({
                    url: `/api/bookings/${bookingId}`,
                    method: 'PUT',
                    body
                });
            }
        }),
        getBookingBasketMinutes: builder.query({
            query: ({ basketId }) => ({
                url: '/api/bookings/basket/minutes',
                method: 'GET',
                params: { basket_id: basketId }
            })
        }),
        getMinutes: builder.query({
            query: ({ products }) => {
                const params = productsToParams(products);
                return {
                    url: '/api/bookings/minutes',
                    method: 'GET',
                    params
                };
            }
        }),
        getBooking: builder.query({
            query: (bookingId) => ({
                url: `/api/bookings/${bookingId}`,
                method: 'GET'
            })
        })
    })
});
export const { useGetEnabledDaysQuery, usePutUpdateBookingMutation, usePostNewBookingMutation, useGetBookingBasketMinutesQuery, useLazyGetBookingBasketMinutesQuery, useGetBookingQuery, useGetMinutesQuery } = availabilityApi;
