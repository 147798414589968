var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '@store/index';
import { useGetBasketQuery } from '@store/services/payment';
import { useGetAdvisorsQuery, useGetUsersQuery } from '@store/services/users';
import { useGetEnabledDaysQuery, usePostNewBookingMutation, usePutUpdateBookingMutation, useGetBookingQuery } from '@store/services/booking';
import { setAvailabilityOptions } from '@store/slices/availabilitySlice';
import { addCompleted, handleBack, handleNext, removeCompleted } from '@store/slices/bookingWizardSlice';
import { addMonths, isBefore, startOfMonth } from 'date-fns';
import { findFirstAvailableDay, isDateDisabled, getFormattedBookingDateTime, getTimeIntervalTextId, shouldModifierBeApplied, isTimeSlotForSelectedDate } from './BookTime.utils';
import Checkbox from '@frontend-components/components/Checkbox';
import Spinner from '@frontend-components/components/Spinner';
import DayPickerComponent from '@frontend-components/components/DayPicker';
import Advisors from '@components/Advisors';
import ColorLabels from './components/ColorLabels/ColorLabels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { StyledBookWrapper, StyledButtonWrapper, StyledDayPickerWrapper, StyledAvailableTimes, StyledAvailabilityWrapper, StyledAvailability, StyledAvailabilityParagraph, StyledAdvisorAvailability, StyledBookingButton, StyledBestTimesWrapper, StyledTeamsWrapper, StyledButtonsWrapper, TheBookTimeWrapper, StyledBookingDetailsWrapper, weekNumberStyles, StyledSelectedSlotDetails, SelectedSlotDetailsWrapper, StyledButton } from './BookTime.styles';
import './BookTime.scss';
import { TimeIntervals } from '@constants';
import { StyledBackWrapper, TempBackButtonWrapper } from '../../containers/CreateBooking/CreateBooking.styles';
import { StyledWrapper } from '../../global.styles';
import { getUrlParam } from '@frontend-components/utils/urls';
import { BookingStatus } from '@store/services/booking.types';
import { COLOR_LABELS } from './BookTime.constants';
import { getIdentifier } from '@components/ManualBooking/ManualBooking.utils';
import { useSnackbar } from 'notistack';
import { useEcommerceFlag } from '@hooks/useEcommerceFlag';
const FIRST_DAY_OF_WEEK = 1;
const BookTime = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();
    const { isEcommerceEnabled } = useEcommerceFlag();
    const userId = useAppSelector((state) => { var _a; return (_a = state.userId.payload) === null || _a === void 0 ? void 0 : _a.userId; });
    const currentOrder = useAppSelector((state) => state.currentOrder);
    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const visitedMonths = useRef([]);
    const timerRef = useRef();
    const bookingId = Number(getUrlParam(window.location.href, 'booking_id'));
    const { data: basketData } = useGetBasketQuery({ userId: Number(userId) }, { skip: userId == null || isEcommerceEnabled });
    const { data: userData } = useGetUsersQuery({
        userId: Number(userId)
    });
    const productsInPayload = isEcommerceEnabled
        ? { products: currentOrder.items }
        : { basket_id: (_a = basketData === null || basketData === void 0 ? void 0 : basketData.id.toString()) !== null && _a !== void 0 ? _a : '' };
    const productsSkipCondition = isEcommerceEnabled
        ? (currentOrder === null || currentOrder === void 0 ? void 0 : currentOrder.items.length) === 0
        : (basketData === null || basketData === void 0 ? void 0 : basketData.id) == null;
    const { data: advisorsData } = useGetAdvisorsQuery(Object.assign(Object.assign({}, productsInPayload), { user_id: userId !== null && userId !== void 0 ? userId : 0 }), { skip: productsSkipCondition || userId === 0 });
    const [handleNewBooking, { isLoading: isPostNewBookingLoading, isSuccess: isPostNewBookingSuccess }] = usePostNewBookingMutation();
    const [updateBooking, { isLoading: isUpdateBookingLoading, isSuccess: isUpdateBookingSuccess }] = usePutUpdateBookingMutation();
    const { selectedAdvisorId, selectedAdvisorType } = useAppSelector((state) => state.advisorId);
    const adminName = getUrlParam(window.location.href, 'admin_name');
    const { selectedTimeIntervals, selectedBookingDate, selectedBookingTimeKitId, selectedTeamsMeetingLink } = useAppSelector((state) => state.availability);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const { bookingMinutes, manualMinutes } = useAppSelector((state) => state.availability);
    const basketSpecificPayload = {
        basket_id: (_b = basketData === null || basketData === void 0 ? void 0 : basketData.id.toString()) !== null && _b !== void 0 ? _b : '',
        products: (_c = basketData === null || basketData === void 0 ? void 0 : basketData.items) === null || _c === void 0 ? void 0 : _c.map((item) => ({
            product_type: item.product,
            purchase_type: item.purchase_type,
            identifier: item.identifier != null
                ? getIdentifier(item.identifier)
                : `user:${userId}`
        }))
    };
    const productsSpecificPayload = {
        products: (_d = currentOrder === null || currentOrder === void 0 ? void 0 : currentOrder.items) === null || _d === void 0 ? void 0 : _d.map((product) => ({
            product_type: product.productName,
            purchase_type: product.purchaseType
        }))
    };
    const payload = isEcommerceEnabled ? productsSpecificPayload : basketSpecificPayload;
    const month = `${selectedMonth === null || selectedMonth === void 0 ? void 0 : selectedMonth.getFullYear()}-${selectedMonth != null ? selectedMonth.getMonth() + 1 : ''}`;
    const { data: enabledDaysData, isFetching: isEnabledDaysFetching } = useGetEnabledDaysQuery(Object.assign(Object.assign({ minutes: bookingMinutes, month, user_id: userId != null ? String(userId) : '' }, (isEcommerceEnabled ? { products: currentOrder.items } : { basket_id: (_e = basketData === null || basketData === void 0 ? void 0 : basketData.id.toString()) !== null && _e !== void 0 ? _e : '' })), { notice_type: 'internal', advisor_id: selectedAdvisorId != null ? String(selectedAdvisorId) : '0', advisor_type: selectedAdvisorType !== null && selectedAdvisorType !== void 0 ? selectedAdvisorType : 'all', time_interval_type: (_f = selectedTimeIntervals) !== null && _f !== void 0 ? _f : '' }), {
        pollingInterval: 60000,
        skip: bookingMinutes === 0
            || productsSkipCondition
            || userId == null
            || selectedAdvisorType == null
    });
    // Add this effect to check if selected date is still valid after polling
    useEffect(() => {
        var _a;
        if (!isEnabledDaysFetching && enabledDaysData && selectedBookingDate) {
            const isDateStillEnabled = (_a = enabledDaysData.available_slots) === null || _a === void 0 ? void 0 : _a.some((slot) => slot.date === selectedBookingDate);
            if (!isDateStillEnabled) {
                // Clear the selected date
                dispatch(setAvailabilityOptions({
                    selectedBookingDate: '',
                    selectedBookingTimeKitId: ''
                }));
                enqueueSnackbar('Din valgte tid er ikke længere tilgængelig', {
                    variant: 'error',
                    persist: false,
                    style: {
                        width: '400px', // Fixed width for better visibility
                        textAlign: 'center'
                    }
                });
                // Show toast notification
            }
        }
    }, [enabledDaysData, isEnabledDaysFetching, selectedBookingDate]);
    const { data: currentBookingData } = useGetBookingQuery(bookingId, {
        skip: bookingId == null || bookingId === 0
    });
    const enabledDaysDate = ((_g = enabledDaysData === null || enabledDaysData === void 0 ? void 0 : enabledDaysData.enabled_days) === null || _g === void 0 ? void 0 : _g.map((enabledDayData) => enabledDayData.date)) || [];
    const handleClickCreateBooking = () => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedBookingDate == null
            || selectedBookingTimeKitId == null
            || (isEcommerceEnabled && currentOrder.items.length === 0)
            || userId == null
            || (userData === null || userData === void 0 ? void 0 : userData.fullname) == null)
            return;
        if (bookingId !== 0 && adminName != null) {
            updateBooking(Object.assign(Object.assign({ bookingId, status_changed_by: adminName, status: BookingStatus.Rebooked }, payload), { is_paid: false, origin: 'backend', date: selectedBookingDate, timekit_id: selectedBookingTimeKitId, minutes: bookingMinutes || 0, send_teams_meeting_link: selectedTeamsMeetingLink, is_manual: false, property_type: '', manual_minutes: manualMinutes, order_id: isEcommerceEnabled ? currentOrder.orderIdentifier : null, amount: isEcommerceEnabled ? currentOrder.totalPrice : null }));
            return;
        }
        handleNewBooking(Object.assign(Object.assign({ user_id: userId, booked_by: adminName !== null && adminName !== void 0 ? adminName : ((userData === null || userData === void 0 ? void 0 : userData.fullname) || ''), is_paid: false, origin: 'backend' }, payload), { date: selectedBookingDate, timekit_id: selectedBookingTimeKitId, minutes: bookingMinutes || 0, send_teams_meeting_link: selectedTeamsMeetingLink, is_manual: false, property_type: '', manual_minutes: manualMinutes, order_id: isEcommerceEnabled ? currentOrder.orderIdentifier : null, amount: isEcommerceEnabled ? currentOrder.totalPrice : null }));
    });
    useEffect(() => {
        const timer = timerRef.current;
        return () => clearTimeout(timer);
    }, []);
    useEffect(() => {
        if (enabledDaysData == null || isEnabledDaysFetching)
            return;
        const firstAvailableDay = findFirstAvailableDay(selectedMonth, enabledDaysDate);
        // if there's not any available day, navigate to next month 3 times until there's an available day
        if (firstAvailableDay == null && visitedMonths.current.length < 3) {
            const newSelectedMonth = addMonths(selectedMonth, 1).getMonth();
            visitedMonths.current.push(newSelectedMonth);
            handleMonthChange(startOfMonth(addMonths(selectedMonth, 1)));
            return;
        }
        if (firstAvailableDay
            && firstAvailableDay.getTime() !== selectedDate.getTime())
            setSelectedDate(firstAvailableDay);
    }, [selectedMonth, enabledDaysData === null || enabledDaysData === void 0 ? void 0 : enabledDaysData.enabled_days, isEnabledDaysFetching]);
    useEffect(() => {
        if (isPostNewBookingSuccess || isUpdateBookingSuccess) {
            dispatch(setAvailabilityOptions({
                selectedTimeIntervals: null,
                selectedBookingDate: '',
                selectedBookingTimeKitId: '',
                bookingMinutes: 0,
                selectedTeamsMeetingLink: false
            }));
            dispatch(handleNext());
            dispatch(addCompleted(2));
        }
    }, [isPostNewBookingSuccess, isUpdateBookingSuccess]);
    useEffect(() => {
        if (currentBookingData) {
            dispatch(setAvailabilityOptions({
                selectedTeamsMeetingLink: !!currentBookingData.teams_meeting_link
            }));
        }
    }, [currentBookingData]);
    const handleMonthChange = (newSelectedMonthDate) => {
        const todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);
        const newValidatedSelectedMonthDate = isBefore(newSelectedMonthDate, todayDate)
            ? todayDate
            : newSelectedMonthDate;
        setSelectedMonth(newValidatedSelectedMonthDate);
    };
    const handleBackClick = () => {
        dispatch(handleBack());
        dispatch(removeCompleted(1));
    };
    return (_jsx(StyledWrapper, { "data-cy": "create-booking-Book-wrapper", children: _jsxs(TheBookTimeWrapper, { children: [_jsx(Advisors, {}), _jsx(StyledBestTimesWrapper, { children: _jsx("p", { "data-cy": "create-booking-times", children: t('bookingTheAvailability.times') }) }), _jsxs(StyledBookWrapper, { children: [_jsxs(StyledButtonWrapper, { children: [_jsxs(StyledButton, { "data-cy": "create-booking-clear-time-intervals", disabled: isEnabledDaysFetching, onClick: () => dispatch(setAvailabilityOptions({
                                        selectedTimeIntervals: TimeIntervals.day
                                    })), "$active": selectedTimeIntervals === TimeIntervals.day, children: [t('bookingTheAvailability.allDay'), _jsx(FontAwesomeIcon, { icon: faTimes, style: { paddingLeft: '5px' } })] }), _jsx(StyledButton, { "data-cy": "create-booking-time-intervals-before-dinner", disabled: isEnabledDaysFetching, onClick: () => dispatch(setAvailabilityOptions({
                                        selectedTimeIntervals: TimeIntervals.before_dinner
                                    })), "$active": selectedTimeIntervals === TimeIntervals.before_dinner, children: t('bookingTheAvailability.beforeDinner') }), _jsx(StyledButton, { "data-cy": "create-booking-time-intervals-after-dinner", disabled: isEnabledDaysFetching, onClick: () => dispatch(setAvailabilityOptions({
                                        selectedTimeIntervals: TimeIntervals.after_dinner
                                    })), "$active": selectedTimeIntervals === TimeIntervals.after_dinner, children: t('bookingTheAvailability.afterDinner') }), _jsx(StyledButton, { "data-cy": "create-booking-time-intervals-evening", disabled: isEnabledDaysFetching, onClick: () => dispatch(setAvailabilityOptions({
                                        selectedTimeIntervals: TimeIntervals.evening
                                    })), "$active": selectedTimeIntervals === TimeIntervals.evening, children: t('bookingTheAvailability.evening') })] }), _jsxs(StyledBookingDetailsWrapper, { children: [_jsx(StyledDayPickerWrapper, { children: _jsx(DayPickerComponent, { selected: selectedDate, onDayClick: (date) => setSelectedDate(date), month: selectedMonth, showWeekNumber: true, styles: {
                                            weeknumber: weekNumberStyles,
                                            month: {
                                                textTransform: 'capitalize'
                                            }
                                        }, onMonthChange: handleMonthChange, weekStartsOn: FIRST_DAY_OF_WEEK, disabled: (date) => isDateDisabled(date, enabledDaysDate, isEnabledDaysFetching), "data-cy": "create-booking-day-picker", modifiers: {
                                            isRedDayButton: [
                                                (date) => {
                                                    var _a;
                                                    return shouldModifierBeApplied(date, 1, (_a = enabledDaysData === null || enabledDaysData === void 0 ? void 0 : enabledDaysData.enabled_days) !== null && _a !== void 0 ? _a : []);
                                                }
                                            ],
                                            isYellowDayButton: [
                                                (date) => {
                                                    var _a;
                                                    return shouldModifierBeApplied(date, 2, (_a = enabledDaysData === null || enabledDaysData === void 0 ? void 0 : enabledDaysData.enabled_days) !== null && _a !== void 0 ? _a : []);
                                                }
                                            ],
                                            isGreenDayButton: [
                                                (date) => {
                                                    var _a;
                                                    return shouldModifierBeApplied(date, 3, (_a = enabledDaysData === null || enabledDaysData === void 0 ? void 0 : enabledDaysData.enabled_days) !== null && _a !== void 0 ? _a : []);
                                                }
                                            ]
                                        }, modifiersClassNames: {
                                            isRedDayButton: 'redDayButton',
                                            isYellowDayButton: 'yellowDayButton',
                                            isGreenDayButton: 'greenDayButton'
                                        } }) }), _jsxs(StyledAdvisorAvailability, { children: [_jsx("p", { "data-cy": "create-booking-available-slots-feedback", children: t('bookingTheAvailability.availableSlotsFeedback', {
                                                selectedDate: `${selectedDate.getDate()}-${selectedDate.getMonth()
                                                    + 1}`,
                                                selectedTimeIntervals: t(getTimeIntervalTextId(selectedTimeIntervals)).toLowerCase()
                                            }) }), _jsx(StyledAvailableTimes, { children: _jsx(StyledAvailabilityWrapper, { children: isEnabledDaysFetching ? (_jsx(Spinner, { position: "center" })) : ((_h = enabledDaysData === null || enabledDaysData === void 0 ? void 0 : enabledDaysData.available_slots) === null || _h === void 0 ? void 0 : _h.map((item) => (isTimeSlotForSelectedDate(item.date, selectedDate) ? (_jsx(StyledAvailability, { "data-cy": "create-booking-availability", advisorColor: item.legal_advisor_type, "$selected": selectedBookingDate === item.date, children: _jsx(StyledAvailabilityParagraph, { onClick: () => dispatch(setAvailabilityOptions({
                                                            selectedBookingDate: item.date,
                                                            selectedBookingTimeKitId: item.timekit_id
                                                        })), children: _jsxs("span", { children: [`${item.start} - ${item.end} `, _jsx("small", { children: `${item.legal_advisor_name}` })] }) }) }, item.date)) : null))) }) }), selectedBookingDate.length > 0 && selectedAdvisorId != null && (_jsx(SelectedSlotDetailsWrapper, { children: _jsxs(StyledSelectedSlotDetails, { children: [_jsx("p", { children: getFormattedBookingDateTime(selectedBookingDate, bookingMinutes) }), _jsx("p", { children: (_j = advisorsData === null || advisorsData === void 0 ? void 0 : advisorsData.data.find(({ id }) => id === selectedAdvisorId)) === null || _j === void 0 ? void 0 : _j.name })] }) }))] })] }), _jsx(ColorLabels, { colorLabels: COLOR_LABELS })] }), _jsxs(StyledTeamsWrapper, { children: [_jsx("p", { children: t('bookingTheAvailability.other') }), _jsx(Checkbox, { handleOnChange: () => dispatch(setAvailabilityOptions({
                                selectedTeamsMeetingLink: !selectedTeamsMeetingLink
                            })), checked: selectedTeamsMeetingLink, label: t('bookingTheAvailability.linkToTeam'), name: t('bookingTheAvailability.linkToTeam') })] }), _jsxs(StyledButtonsWrapper, { children: [_jsxs(TempBackButtonWrapper, { onClick: handleBackClick, children: [_jsx(FontAwesomeIcon, { icon: faArrowLeftLong }), _jsx(StyledBackWrapper, { children: t('bookingTheAvailability.back') })] }), _jsx(StyledBookingButton, { "data-cy": "create-booking-save-booking", color: "pink", disabled: !selectedBookingDate || !selectedBookingTimeKitId, onClick: handleClickCreateBooking, isLoading: isPostNewBookingLoading || isUpdateBookingLoading, children: t('bookingTheAvailability.saveBooking') })] })] }) }));
};
export default BookTime;
