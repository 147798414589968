var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest, loginSuccess, loginFailure, logoutRequest, logoutSuccess, updateAccessToken, checkSessionRequest, checkSessionSuccess, checkSessionFailure } from './authSlice';
import { msalInstance, loginRequest as msalLoginRequest, initializeMsal } from '../../MSALConfig';
// Track initialization state
let isMsalInitialized = false;
// Initialize MSAL - should be called at app startup
export const initializeAuth = createAsyncThunk('auth/initialize', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { rejectWithValue }) {
    if (!isMsalInitialized) {
        try {
            const initResult = yield initializeMsal();
            if (initResult) {
                isMsalInitialized = true;
                return true;
            }
            return rejectWithValue('Failed to initialize MSAL');
        }
        catch (error) {
            console.error('MSAL initialization error:', error);
            return rejectWithValue('Failed to initialize MSAL');
        }
    }
    return true;
}));
// Helper function to ensure MSAL is initialized before proceeding
const ensureMsalInitialized = () => __awaiter(void 0, void 0, void 0, function* () {
    if (!isMsalInitialized) {
        try {
            const success = yield initializeMsal();
            isMsalInitialized = success;
            if (!success) {
                throw new Error('Failed to initialize MSAL');
            }
        }
        catch (error) {
            console.error('MSAL initialization error:', error);
            throw error;
        }
    }
    return true;
});
// Helper function to convert MSAL AccountInfo to SerializableUser
const convertToSerializableUser = (account) => ({
    homeAccountId: account.homeAccountId,
    environment: account.environment,
    tenantId: account.tenantId,
    username: account.username,
    localAccountId: account.localAccountId,
    name: account.name,
    idTokenClaims: account.idTokenClaims
});
// Check if the user is already logged in (from session storage)
export const checkSession = createAsyncThunk('auth/checkSession', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { dispatch }) {
    dispatch(checkSessionRequest());
    try {
        // Ensure MSAL is initialized before proceeding
        yield ensureMsalInitialized();
        // Ensure MSAL is initialized (should be handled by AuthProvider, but double-check)
        if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length === 0) {
            try {
                yield msalInstance.handleRedirectPromise();
            }
            catch (redirectError) {
                console.error('Error handling redirect:', redirectError);
            }
        }
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            const account = accounts[0];
            // Try to get a token silently
            try {
                const silentRequest = {
                    scopes: msalLoginRequest.scopes,
                    account
                };
                const response = yield msalInstance.acquireTokenSilent(silentRequest);
                // Convert account to serializable format
                const serializableUser = convertToSerializableUser(account);
                dispatch(checkSessionSuccess({
                    user: serializableUser,
                    accessToken: response.accessToken
                }));
                return {
                    user: serializableUser,
                    accessToken: response.accessToken
                };
            }
            catch (error) {
                console.error('Silent token acquisition failed:', error);
                if (error instanceof InteractionRequiredAuthError) {
                    try {
                        // Try popup as a fallback
                        const popupRequest = {
                            scopes: msalLoginRequest.scopes,
                            account
                        };
                        const response = yield msalInstance.acquireTokenPopup(popupRequest);
                        // Convert account to serializable format
                        const serializableUser = convertToSerializableUser(account);
                        dispatch(checkSessionSuccess({
                            user: serializableUser,
                            accessToken: response.accessToken
                        }));
                        return {
                            user: serializableUser,
                            accessToken: response.accessToken
                        };
                    }
                    catch (popupError) {
                        console.error('Popup token acquisition failed:', popupError);
                        dispatch(checkSessionFailure());
                    }
                }
                else {
                    dispatch(checkSessionFailure());
                }
            }
        }
        else {
            dispatch(checkSessionFailure());
        }
    }
    catch (error) {
        console.error('Error checking auth session:', error);
        dispatch(checkSessionFailure());
    }
    return {
        user: null,
        accessToken: null
    };
}));
// Login action
export const login = createAsyncThunk('auth/login', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { dispatch }) {
    dispatch(loginRequest());
    try {
        // Ensure MSAL is initialized before proceeding
        yield ensureMsalInitialized();
        const result = yield msalInstance.loginPopup(msalLoginRequest);
        if (result) {
            // Now get an access token for API
            try {
                const tokenResponse = yield msalInstance.acquireTokenSilent({
                    scopes: msalLoginRequest.scopes,
                    account: result.account
                });
                // Convert account to serializable format
                const serializableUser = convertToSerializableUser(result.account);
                dispatch(loginSuccess({
                    user: serializableUser,
                    accessToken: tokenResponse.accessToken
                }));
                return {
                    user: serializableUser,
                    accessToken: tokenResponse.accessToken
                };
            }
            catch (tokenError) {
                console.error('Error acquiring token silently:', tokenError);
                if (tokenError instanceof InteractionRequiredAuthError) {
                    // Fallback to interaction if silent token acquisition fails
                    try {
                        const tokenResponse = yield msalInstance.acquireTokenPopup({
                            scopes: msalLoginRequest.scopes,
                            account: result.account
                        });
                        // Convert account to serializable format
                        const serializableUser = convertToSerializableUser(result.account);
                        dispatch(loginSuccess({
                            user: serializableUser,
                            accessToken: tokenResponse.accessToken
                        }));
                        return {
                            user: serializableUser,
                            accessToken: tokenResponse.accessToken
                        };
                    }
                    catch (interactiveError) {
                        console.error('Error acquiring token interactively:', interactiveError);
                        dispatch(loginFailure('Failed to get access token'));
                        throw interactiveError;
                    }
                }
                else {
                    dispatch(loginFailure('Failed to get access token'));
                    throw tokenError;
                }
            }
        }
    }
    catch (error) {
        console.error('Login failed:', error);
        dispatch(loginFailure('Login failed'));
        throw error;
    }
    return {
        user: null,
        accessToken: null
    };
}));
// Logout action
export const logout = createAsyncThunk('auth/logout', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { dispatch }) {
    dispatch(logoutRequest());
    try {
        // Ensure MSAL is initialized before proceeding
        yield ensureMsalInitialized();
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            const request = {
                account: accounts[0],
                postLogoutRedirectUri: window.location.origin
            };
            yield msalInstance.logoutPopup(request);
        }
        dispatch(logoutSuccess());
    }
    catch (error) {
        console.error('Logout error:', error);
        // Still mark as logged out even if there's an error
        dispatch(logoutSuccess());
    }
    return {
        user: null,
        accessToken: null
    };
}));
// Refresh the access token
export const refreshToken = createAsyncThunk('auth/refreshToken', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { dispatch, getState }) {
    try {
        // Ensure MSAL is initialized before proceeding
        yield ensureMsalInitialized();
        const state = getState();
        const { user } = state.auth;
        if (!user) {
            console.error('Cannot refresh token: No user account found');
            throw new Error('No user account found');
        }
        // Find the account in MSAL's cache
        const accounts = msalInstance.getAllAccounts();
        const account = accounts.find((a) => a.homeAccountId === user.homeAccountId);
        if (!account) {
            console.error('Cannot refresh token: Account not found in MSAL cache');
            throw new Error('Account not found in MSAL cache');
        }
        const silentRequest = {
            scopes: msalLoginRequest.scopes,
            account
        };
        const response = yield msalInstance.acquireTokenSilent(silentRequest);
        dispatch(updateAccessToken(response.accessToken));
        return response.accessToken;
    }
    catch (error) {
        console.error('Error refreshing token:', error);
        if (error instanceof InteractionRequiredAuthError) {
            try {
                const state = getState();
                const { user } = state.auth;
                if (!user) {
                    throw new Error('No user account found');
                }
                // Find the account in MSAL's cache
                const accounts = msalInstance.getAllAccounts();
                const account = accounts.find((a) => a.homeAccountId === user.homeAccountId);
                if (!account) {
                    throw new Error('Account not found in MSAL cache');
                }
                const response = yield msalInstance.acquireTokenPopup({
                    scopes: msalLoginRequest.scopes,
                    account
                });
                dispatch(updateAccessToken(response.accessToken));
                return response.accessToken;
            }
            catch (interactiveError) {
                console.error('Error acquiring token interactively:', interactiveError);
                throw interactiveError;
            }
        }
        else {
            throw error;
        }
    }
}));
