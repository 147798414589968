import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Box, useMediaQuery, Divider, Container, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Menu as MenuIcon, People as PeopleIcon, Dashboard as DashboardIcon, History as HistoryIcon, Person as PersonIcon, CalendarMonth as CalendarIcon, Logout as LogoutIcon } from '@mui/icons-material';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
const Navigation = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [drawerOpen, setDrawerOpen] = useState(false);
    const navigationItems = [
        {
            name: 'Availability',
            path: '/staff',
            icon: _jsx(PeopleIcon, {}),
            description: 'View and manage staff availability'
        },
        {
            name: 'Schedules',
            path: '/overview',
            icon: _jsx(DashboardIcon, {}),
            description: 'Overview of all staff schedules'
        },
        {
            name: 'Booking History',
            path: '/booking-history',
            icon: _jsx(HistoryIcon, {}),
            description: 'View past and upcoming bookings'
        },
        {
            name: 'User History',
            path: '/user-history',
            icon: _jsx(PersonIcon, {}),
            description: 'View user booking history'
        },
        {
            name: 'Calendar',
            path: '/calendar',
            icon: _jsx(CalendarIcon, {}),
            description: 'View and manage calendar'
        },
        {
            name: 'Double Bookings',
            path: '/dashboard',
            icon: _jsx(CalendarIcon, {}),
            description: 'View and manage double bookings'
        }
    ];
    const handleNavigation = (path) => {
        navigate(path);
        if (isMobile) {
            setDrawerOpen(false);
        }
    };
    const handleLogout = () => {
        instance.logoutRedirect();
    };
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };
    const renderNavItems = () => navigationItems.map((item) => {
        const isActive = location.pathname === item.path;
        return isMobile ? (_jsxs(ListItem, { component: "button", onClick: () => handleNavigation(item.path), sx: {
                backgroundColor: isActive ? `${theme.palette.primary.main}10` : 'transparent',
                borderLeft: isActive ? `4px solid ${theme.palette.primary.main}` : '4px solid transparent',
                pl: isActive ? 2 : 3
            }, children: [_jsx(ListItemIcon, { sx: { color: isActive ? theme.palette.primary.main : 'inherit' }, children: item.icon }), _jsx(ListItemText, { primary: item.name })] }, item.path)) : (_jsx(Tooltip, { title: item.description, arrow: true, children: _jsx(Button, { color: isActive ? 'primary' : 'inherit', onClick: () => handleNavigation(item.path), startIcon: item.icon, sx: {
                    minWidth: 'auto',
                    px: 2,
                    position: 'relative',
                    '&::after': isActive ? {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '3px',
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: '3px 3px 0 0'
                    } : {}
                }, children: item.name }) }, item.path));
    });
    const renderMobileDrawer = () => (_jsx(Drawer, { anchor: "left", open: drawerOpen, onClose: toggleDrawer, children: _jsxs(Box, { sx: { width: 280 }, role: "presentation", children: [_jsx(Box, { sx: {
                        p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }, children: _jsx(Typography, { variant: "h6", component: "div", color: "primary", children: "My Booking Utils" }) }), _jsx(Divider, {}), _jsx(List, { children: renderNavItems() }), isAuthenticated && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(List, { children: _jsxs(ListItem, { component: "button", onClick: handleLogout, children: [_jsx(ListItemIcon, { children: _jsx(LogoutIcon, {}) }), _jsx(ListItemText, { primary: "Logout" })] }) })] }))] }) }));
    return (_jsxs(_Fragment, { children: [_jsx(AppBar, { position: "static", elevation: 1, color: "default", sx: { backgroundColor: 'white' }, children: _jsx(Container, { maxWidth: "xl", children: _jsxs(Toolbar, { disableGutters: true, sx: { justifyContent: 'space-between' }, children: [_jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [isMobile && (_jsx(IconButton, { edge: "start", color: "inherit", "aria-label": "menu", onClick: toggleDrawer, sx: { mr: 1 }, children: _jsx(MenuIcon, {}) })), _jsx(Typography, { variant: "h6", noWrap: true, component: "div", sx: {
                                            mr: 2,
                                            display: 'flex',
                                            fontWeight: 700,
                                            color: theme.palette.primary.main
                                        }, children: "Booking Utils" }), !isMobile && (_jsx(Box, { sx: { display: 'flex' }, children: renderNavItems() }))] }), isAuthenticated && !isMobile && (_jsx(Button, { color: "inherit", onClick: handleLogout, startIcon: _jsx(LogoutIcon, {}), children: "Logout" }))] }) }) }), isMobile && renderMobileDrawer()] }));
};
export default Navigation;
